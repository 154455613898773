import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-tabs/style/react-tabs.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DialogueContextProvider } from "./components/DialogueContext";

import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<DialogueContextProvider>
		<React.StrictMode>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	</DialogueContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
