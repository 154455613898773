export { default as basic_actions } from "./basic_actions.json";
export { default as basic_conditions } from "./basic_conditions.json";
export { default as boolean } from "./boolean.json";
export { default as character_stats } from "./character_stats.json";
export { default as compare } from "./compare.json";
export { default as equipment_slot } from "./equipment_slot.json";
export { default as farming } from "./farming.json";
export { default as items } from "./items.json";
export { default as npc_interaction } from "./npc_interaction.json";
export { default as quest } from "./quest.json";
export { default as quests } from "./quests.json";
export { default as respawns } from "./respawns.json";
export { default as shops } from "./shops.json";
export { default as skills } from "./skills.json";
export { default as sounds } from "./sounds.json";
export { default as simple_compare } from "./simple_compare.json";
export { default as switchs } from "./switchs.json";
export { default as account_types } from "./account_types.json";
export { default as inventories } from "./inventories.json";
export { default as servants } from "./servants.json";
export { default as teleports } from "./teleports.json";
export const dialogues = [];
export const github = [];
